import axios from "axios";
import API_ENDPOINTS from "../config";

export const takeBackup = async () => {
    try {
        await axios.post(API_ENDPOINTS.TAKE_BACKUP);
        return "Backup taken successfully";
    } catch (error) {
        console.error("Error taking backup:", error);

        if (error.response) {
            // The request was made and the server responded with a status code
            const status = error.response.status;
            if (status === 500) {
                throw new Error("Internal Server Error: Failed to take backup");
            } else {
                throw new Error(`Server returned status code ${status}`);
            }
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error("No response received from the server");
        } else {
            // Something happened in setting up the request
            throw new Error("Error setting up the request");
        }
    }
};

export const importBackup = async (file) => {
    console.log("file", file);

    try {
        if (!file) {
            throw new Error('No file selected.');
        }

        const formData = new FormData();
        formData.append('file', file); // 'file' should match the field name expected by the server
        console.log("formData", formData);


        const response = await axios.post(API_ENDPOINTS.IMPORT_BACKUP, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.message;
    } catch (error) {
        console.error('Error importing backup:', error);

        if (error.response) {
            const status = error.response.status;
            const statusText = error.response.statusText;

            if (status === 500) {
                throw new Error(`Internal Server Error: ${statusText}`);
            } else {
                throw new Error(`Server returned status code ${status}: ${statusText}`);
            }
        } else if (error.request) {
            throw new Error('No response received from the server');
        } else {
            throw new Error('Error setting up the request');
        }
    }
};
