import axios from 'axios';
import API_ENDPOINTS from "../config";


const OwnerService = {
    async getAllOwners() {
        try {
            const response = await axios.get(API_ENDPOINTS.OWNER);
            return response.data;
        } catch (error) {
            console.error('Error fetching owners:', error);
            throw error;
        }
    },

    async getOwnerById(ownerId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.OWNER}/${ownerId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching owner with ID ${ownerId}:`, error);
            throw error;
        }
    },

    async createOwner(ownerData) {
        try {
            const response = await axios.post(`${API_ENDPOINTS.OWNER}`, ownerData);
            return response.data;
        } catch (error) {
            console.error('Error creating owner:', error);
            throw error;
        }
    },

    async updateOwner(ownerId, ownerData) {
        try {
            console.log('ownerData :', ownerData);
            const response = await axios.put(`${API_ENDPOINTS.OWNER}/${ownerId}`, ownerData);
            return response.data;
        } catch (error) {
            console.error(`Error updating owner with ID ${ownerId}:`, error);
            throw error;
        }
    },

    async deleteOwner(ownerId) {
        try {
            await axios.delete(`${API_ENDPOINTS.OWNER}/${ownerId}`);
        } catch (error) {
            console.error(`Error deleting owner with ID ${ownerId}:`, error);
            throw error;
        }
    }
};

export default OwnerService;