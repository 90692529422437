import * as Yup from 'yup';
import RegistrationService from "../data/registrationService";


const textValidation = Yup.string()
    .required('Name is required');

const phoneValidation = Yup.string()
    .matches(/^\d+$/, 'Only numbers are allowed')
    .required('Phone is required');

const emailValidation = Yup.string()
    .email('Invalid email')
    .required('Email is required');

const validationSchemas = {
    agency: Yup.object().shape({
        name: textValidation,
        phone: phoneValidation,
        address: textValidation,
        country: textValidation,
        city: textValidation,
        email: emailValidation,
    }),
    category: Yup.object().shape({
        name: textValidation,
        description: textValidation,
    }),
    customer: Yup.object().shape({
        address: textValidation,
        city: textValidation,
        country: textValidation,
        email: emailValidation,
        first_name: textValidation,
        last_name: textValidation,
        phone: phoneValidation,
    }),
    document: Yup.object().shape({
        document_content: Yup.string().required('Document Content is required'),
        document_id: Yup.string().required('Document ID is required'),
        document_name: Yup.string().required('Document Name is required'),
        document_type: Yup.string().required('Document Type is required'),
        trademark_id: Yup.string().required('Trademark ID is required'),
        uploaded_at: Yup.string().required('Uploaded At is required'),
    }),
    owner: Yup.object().shape({
        address: textValidation,
        city: textValidation,
        country: textValidation,
        email: emailValidation,
        first_name: textValidation,
        last_name: textValidation,
        phone: phoneValidation,
    }),
    registration: Yup.object().shape({
        registration_date: Yup.string().required('Registration Date is required'),
        registration_number: Yup.string()
            .required('Registration Number is required')
            .test('unique-registration-number', 'Registration Number must be unique', async function (value) {
                try {
                    let isUniqueResponse = await RegistrationService.checkRegistrationNumberUniqueness(value);
                    console.log('isUniqueResponse:', isUniqueResponse); // Check the response in the console
                    if (isUniqueResponse.isUnique === false) {
                        return this.createError({message: 'This registration number is already in use.'});
                    }
                    return true;
                } catch (error) {
                    console.error('Error checking registration number uniqueness:', error);
                    return false;
                }

            }),
    }),

    role: Yup.object().shape({
        description: Yup.string().required('Description is required'),
        name: Yup.string().required('Name is required'),
        role_id: Yup.string().required('Role ID is required'),
    }),

    service: Yup.object().shape({
        service_name: textValidation,
        description: textValidation,
    }),

    service_invoice: Yup.object().shape({
        amountLUD: Yup.string().required('Amount (LUD) is required'),
        amountUSD: Yup.string().required('Amount (USD) is required'),
        category_id: Yup.string().required('Category ID is required'),
        invoice_date_generated: Yup.string().required('Invoice Date Generated is required'),
        //invoice_due_date: Yup.string().required('Invoice Due Date is required'),
        service_id: Yup.string().required('Service ID is required'),
        trademark_id: Yup.string().required('Trademark ID is required'),
    }),

    service_price: Yup.object().shape({
        priceLUD: Yup.string().required('Price (LUD) is required'),
        priceUSD: Yup.string().required('Price (USD) is required'),
    }),

    trademark: Yup.object().shape({
        name_En: textValidation,
    }),

    user_role: Yup.object().shape({
        role_id: Yup.string().required('Role ID is required'),
        user_id: Yup.string().required('User ID is required'),
        user_role_id: Yup.string().required('User Role ID is required'),
    }),

    user: Yup.object().shape({
        full_name: Yup.string().required('Full Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
    })

}
export default validationSchemas;