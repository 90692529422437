import React, {useEffect, useState} from 'react';
import {Box, IconButton, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import columnNames from '../utlis/columnNames';
import validationSchemas from '../utlis/validationSchemas';
import getDataGridStyles from "../utlis/dataGridStyles";
import CommonForm from './Common/CommonForm';
import Header from "./Layout/header/Header";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import DeleteConfirmationDialog from "./Common/DeleteConfirmDialog";
import {tokens} from "../theme";
import useDataManagement from '../utlis/useDataManagement';
import RegistrationService from "../data/registrationService";
import {fetchCategories} from "../data/categoryService";
import TrademarkService from "../data/trademarkService";
import {formatDate} from "../utlis/dateUtils";
import {useTranslation} from "react-i18next";

function RegisteredApplication() {
    const {t} = useTranslation();
    const entityType = 'registration';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataGridStyle = getDataGridStyles(colors);
    const [trademarkOptions, seTrademarkOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);


    const fetchTrademarkOptions = async () => {
        seTrademarkOptions(await TrademarkService.getTrademarks())
    };
    const fetchCategoryOptions = async () => {
        setCategoryOptions(await fetchCategories())
    };


    const {
        data,
        selectedData,
        deleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleSave,
    } = useDataManagement(
        RegistrationService.getRegisterTrademark,
        RegistrationService.createRegisterTrademark,
        RegistrationService.updateRegisterTrademark,
        RegistrationService.deleteRegisterTrademark,
        entityType
    );

    useEffect(() => {
        fetchData();
        fetchTrademarkOptions();
        fetchCategoryOptions();

    }, []);

    const columns = [
        ...columnNames.registration
            .filter((column) => column.type !== 'hidden')
            .map((column) => ({
                ...column,
                headerName: t(column.headerName),
            })),
        {
            field: 'Actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header
                title={t("Registered Application")}
                subtitle={t("List of Registered Trademarks")}
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={dataGridStyle}
            >
                <DataGrid
                    rows={formatDate(data, ['registration_date'])}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />

                <CommonForm
                    open={isCardOpen}
                    onClose={handleCloseCard}
                    title="Registered Application Form"
                    initialValues={{}}
                    validationSchema={validationSchemas.registration}
                    onSubmit={handleSave}
                    columnNames={columnNames["registration"]}
                    selectedData={selectedData}
                    trademarkOptions={trademarkOptions}
                    categoryOptions={categoryOptions}


                />

                <DeleteConfirmationDialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    onConfirm={handleDeleteConfirmed}
                />

            </Box>
        </Box>
    );
}

export default RegisteredApplication;
