import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, IconButton, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ServicePriceService from '../data/servicePriceService';
import columnNames from '../utlis/columnNames';
import validationSchemas from '../utlis/validationSchemas';
import getDataGridStyles from "../utlis/dataGridStyles";
import CommonForm from './Common/CommonForm';
import Header from "./Layout/header/Header";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import DeleteConfirmationDialog from "./Common/DeleteConfirmDialog";
import {tokens} from "../theme";
import useDataManagement from '../utlis/useDataManagement';
import CustomerService from '../data/customerService';
import ServiceService from '../data/serviceService';
import {useTranslation} from "react-i18next";
import {handleFormSubmit} from "../utlis/dateUtils";

function ServicePrice() {
    const {t} = useTranslation();
    const entityType = 'service_price';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataGridStyle = getDataGridStyles(colors);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomerFormOpen, setCustomerFormOpen] = useState(false);
    const [isServiceFormOpen, setServiceFormOpen] = useState(false);

    const fetchCustomerOptions = async () => {
        setCustomerOptions(await CustomerService.getAllCustomers())
    };
    const fetchServiceOptions = async () => {
        setServiceOptions(await ServiceService.getServices())
    };

    const handleFormSubmit = useCallback(
        async (formData, serviceFunction, setOpenStateFunction, fetchDataFunction) => {
            try {
                // Call the API to create the entity
                await serviceFunction(formData);

                // After successful submission, you can refresh the data and close the dialog
                fetchDataFunction(); // Refresh the data
                setOpenStateFunction(false); // Close the dialog
            } catch (error) {
                // Handle errors if needed
                console.error(`Error creating entity:`, error);
            }
        },
        []
    );

    const handleCustomerFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                CustomerService.createCustomer,
                setCustomerFormOpen,
                fetchCustomerOptions
            );
        },
        [setCustomerFormOpen, fetchCustomerOptions]
    );

    const handleServiceFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                ServiceService.createService,
                setServiceFormOpen,
                fetchServiceOptions
            );
        },
        [setServiceFormOpen, fetchServiceOptions]
    );
    const handleAddNewForm = (field) => {
        if (field === 'customer_name') {
            // Open Customer Form
            setCustomerFormOpen(true);

        } else if (field === 'service_name') {
            // Open Service Form
            setServiceFormOpen(true);
        }
    };


    const {
        data,
        selectedData,
        deleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleSave,
    } = useDataManagement(
        ServicePriceService.getServicePrices,
        ServicePriceService.createServicePrice,
        ServicePriceService.updateServicePrice,
        ServicePriceService.deleteServicePrice,
        entityType
    );

    useEffect(() => {
        fetchData();
        fetchCustomerOptions();
        fetchServiceOptions();
    }, []);

    const columns = [
        ...columnNames.service_price
            .filter((column) => column.type !== 'hidden')
            .map((column) => ({
                ...column,
                headerName: t(column.headerName),
            })),
        {
            field: 'Actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header
                title={t("Trademark Services Price")}
                subtitle={t("List of Services Prices")}
            />
            <Box display="flex" justifyContent="end" mt="20px">
                <Button onClick={handleCreate} color="secondary" variant="contained">
                    {t("Add Service Price")}
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={dataGridStyle}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />

                {isCustomerFormOpen && (
                    <CommonForm
                        open={isCustomerFormOpen}
                        onClose={() => setCustomerFormOpen(false)}
                        title="Customer Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.customer} // Replace with the appropriate validation schema
                        onSubmit={handleCustomerFormSubmit}
                        columnNames={columnNames["customer"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}
                {isServiceFormOpen && (
                    <CommonForm
                        open={isCardOpen}
                        onClose={handleCloseCard}
                        title="Service Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.service}
                        onSubmit={handleServiceFormSubmit}
                        columnNames={columnNames["service"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}

                <CommonForm
                    open={isCardOpen}
                    onClose={handleCloseCard}
                    title="Service Price Form"
                    initialValues={{}}
                    validationSchema={validationSchemas.service_price}
                    onSubmit={handleSave}
                    columnNames={columnNames["service_price"]}
                    selectedData={selectedData}
                    customerOptions={customerOptions}
                    serviceOptions={serviceOptions}
                    onAddNew={handleAddNewForm} // Pass the callback function

                />

                <DeleteConfirmationDialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    onConfirm={handleDeleteConfirmed}
                />

            </Box>
        </Box>
    );
}

export default ServicePrice;
