import dayjs from "dayjs";
import validationSchemas from "./validationSchemas";
import columnNames from "./columnNames";
import TrademarkService from "../data/trademarkService";
import InvoiceService from "../data/invoiceService";
import RegistrationService from "../data/registrationService";

export const formatDate = (data, formatFields) => {
    return data.map(item => {
        const formattedItem = {...item};

        formatFields.forEach(field => {
            if (formattedItem[field] !== null) {
                formattedItem[field] = dayjs(formattedItem[field]).format('DD/MM/YYYY');
            }
        });

        return formattedItem;
    });
};

export const formatDateForDisplay = (dateString) => {
    if (!dateString) return ''; // Return an empty string for null or undefined dates
    return dayjs(dateString).format('DD/MM/YYYY');
};
export const getEntityData = (entityType, dataType) => {
    switch (dataType) {
        case "title":
            return {
                trademark: "Trademark Form",
                service_invoice: "Invoice Form",
                registration: "Registration Form",
            }[entityType] || null;

        case "schema":
            return {
                trademark: validationSchemas.trademark,
                service_invoice: validationSchemas.service_invoice,
                registration: validationSchemas.registration,
            }[entityType] || null;

        case "columns":
            return {
                trademark: columnNames["trademark"],
                service_invoice: columnNames["service_invoice"],
                registration: columnNames["registration"],
                agency: columnNames["agency"],
            }[entityType] || null;

        case "updateService":
            return {
                trademark: TrademarkService.updateTrademark,
                service_invoice: InvoiceService.updateInvoice,
                registration: RegistrationService.updateRegisterTrademark,
            }[entityType] || null
        case "createService":
            return {
                trademark: TrademarkService.createTrademark,
                service_invoice: InvoiceService.createInvoice,
                registration: RegistrationService.createRegisterTrademark,
            }[entityType] || null

        default:
            return null;
    }
}

