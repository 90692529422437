// useDataManagement.js
import {useState} from 'react';

const useDataManagement = (fetchFunction, createFunction, updateFunction, deleteFunction, entityType) => {
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [isCardOpen, setIsCardOpen] = useState(false);

    const fetchData = async () => {
        try {
            const dataResponse = await fetchFunction(); // Use the provided fetch function
            const dataWithId = dataResponse.map((item, index) => ({
                id: index + 1, // You can use any unique identifier here
                ...item,
            }));
            setData(dataWithId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEdit = (item) => {
        setSelectedData(item);
        setIsCardOpen(true);
    };

    const handleCreate = () => {
        setSelectedData(null);
        setIsCardOpen(true);
    };

    const handleCloseCard = () => {
        setSelectedData(null);
        setIsCardOpen(false);
    };

    const handleDelete = (item) => {
        setSelectedData(item);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setSelectedData(null);
    };

    const handleDeleteConfirmed = async () => {
        try {
            await deleteFunction(selectedData[`${entityType}_id`]);
            setDeleteDialogOpen(false);
            setSelectedData(null);
            fetchData();
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    const handleConfirm = (item) => {
        console.log("item", item)
        setSelectedData(item);
        setConfirmDeleteDialogOpen(true);
    };
    const handleConfirmDialogClose = () => {
        setConfirmDeleteDialogOpen(false);
        setSelectedData(null);
    };

    const handleConfirmDialog = async () => {
        selectedData.invoice_due_date = new Date().toISOString();
        const dateStr = selectedData.invoice_date_generated;
        const [day, month, year] = dateStr.split('/');
        // Create a new Date object in the "YYYY-MM-DD" format
        const isoDate = `${year}-${month}-${day}`;
        // Check if isoDate is a valid ISO date string
        if (new Date(isoDate).toISOString() !== 'Invalid Date') {
            selectedData.invoice_date_generated = isoDate; // Assign the ISO date
        } else {
            console.error(`Invalid date string: ${dateStr}`);
        }
        try {
            await updateFunction(selectedData[`${entityType}_id`], selectedData);
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
        handleConfirmDialogClose();
        fetchData();
    };


    const handleSave = async (itemData) => {
        console.log("itemData", itemData);
        if (itemData.invoice_due_date) {
            itemData.invoice_due_date = null
        }
        try {
            if (selectedData && selectedData[`${entityType}_id`]) {
                await updateFunction(selectedData[`${entityType}_id`], itemData);
            } else {
                console.log("create", itemData);
                await createFunction(itemData);
            }
            handleCloseCard();
            fetchData();
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                console.error('Server responded with an error:', error.response.data);
                // Display error messages from the server to the user
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server:', error.request);
                // Inform the user that there was a problem connecting to the server
            } else {
                // Something else happened while setting up the request or processing the response
                console.error('An error occurred:', error.message);
                // Display a generic error message to the user
            }
        }
    };


    return {
        data,
        selectedData,
        deleteDialogOpen,
        confirmDeleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleConfirm,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleConfirmDialogClose,
        handleConfirmDialog,
        handleSave,
        entityType
    };
};

export default useDataManagement;
