import React, {useState} from 'react';
import {ErrorMessage, Form, Formik} from 'formik';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    TextField, Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {tokens} from '../../theme';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TrademarkService from "../../data/trademarkService";
import {useTranslation} from 'react-i18next';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

const CommonForm = ({
                        open,
                        onClose,
                        title,
                        initialValues,
                        validationSchema,
                        onSubmit,
                        columnNames,
                        selectedData,
                        categoryOptions = [],
                        agencyOptions = [],
                        ownerOptions = [],
                        customerOptions = [],
                        serviceOptions = [],
                        userOptions = [],
                        trademarkOptions = [],
                        loggedInUser = [],
                        onAddNew,

                    }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const handleChangeAndBlur = (handleChange, name, value) => {
        handleChange({target: {name, value}});
    };

    const renderTextField = (column, values, handleChange, errors, touched, isReadOnly) => (
        <div>
            <TextField
                name={column.field}
                label={column.required ? `${t(column.headerName)} *` : t(column.headerName)} // Translate the label
                fullWidth
                margin="normal"
                type={column.type === 'Password' ? 'password' : 'text'}
                onChange={handleChange}
                onBlur={handleChange}
                value={values[column.field]}
                error={touched[column.field] && !!errors[column.field]}
                InputProps={{readOnly: isReadOnly}}
                InputLabelProps={{shrink: true}}
            />

            <ErrorMessage name={column.field} component="div" style={{color: 'red'}}/>
        </div>
    );

    const renderLogoInput = (values, setFieldValue) => {
        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

        const handleFileChange = (event) => {
            const file = event.target.files[0];

            if (!file) return; // No file selected

            if (!file.type.startsWith("image/")) {
                alert(
                    "Invalid file type. Please select a valid image (jpeg, jpg, or png)."
                );
                return;
            }
            if (file.size > maxSizeInBytes) {
                alert(
                    "File size exceeds the limit. Please select a smaller image (max 2MB)."
                );
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Image = event.target.result;
                setFieldValue("logo", base64Image);
            };
            reader.readAsDataURL(file);
        };
        return (
            <div>
                <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileChange}
                />
                <ErrorMessage name="logo" component="div" style={{ color: "red" }} />
                {values.logo && (
                    <img
                        src={values.logo}
                        alt="Selected Logo"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                )}
            </div>
        );
    };

    const renderAutocomplete = (column, options, values, setFieldValue, touched, errors, handleChange) => (

        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: '95%' }}>
            <Autocomplete
                name={column.field}
                options={options}
                getOptionLabel={(option) => {
                    // Define the label based on the field
                    if (column.field === 'customer_name' || column.field === 'owner_name') {
                        return `${option.first_name} ${option.last_name}`;
                    } else if (column.field === 'trademark_name') {
                        return option.name_En;
                    } else if (column.field === 'service_name') {
                        return option.service_name;
                    } else if (column.field === 'agency_name' || column.field === 'category_name') {
                        return option.name;
                    } else if (column.field === 'user_name') {
                        return option.full_name;
                    }
                }}
                onChange={async (event, newValue) => {
                    let updatedValue = '';
                    let relatedIdField = '';

                    if (column.field === 'customer_name' || column.field === 'owner_name') {
                        updatedValue = `${newValue.first_name} ${newValue.last_name}`;
                        relatedIdField = `${column.field.split('_')[0]}_id`;
                    } else if (column.field === 'trademark_name') {
                        updatedValue = newValue.name_En; // Adjust accordingly to the actual property name for trademark name
                        relatedIdField = 'trademark_id';
                    } else if (column.field === 'service_name') {
                        updatedValue = newValue.service_name; // Adjust accordingly to the actual property name for service name
                        relatedIdField = 'service_id';
                        const response = await TrademarkService.getPricesByServiceIdAndCustomerId(newValue.service_id, values.customer_id);
                        setFieldValue('amountLUD', response.length > 0 ? response[0].priceLUD : null);
                        setFieldValue('amountUSD', response.length > 0 ? response[0].priceUSD : null);
                    } else if (column.field === 'user_name') {
                        updatedValue = newValue.full_name; // Adjust accordingly to the actual property name for service name
                        relatedIdField = 'user_id';
                    } else if (column.field === 'agency_name') {
                        updatedValue = newValue.name; // Adjust accordingly to the actual property name for service name
                        relatedIdField = 'agency_id';
                    } else if (column.field === 'category_name') {
                        updatedValue = newValue.name; // Adjust accordingly to the actual property name for service name
                        relatedIdField = 'category_id';
                    }
                    setFieldValue(column.field, updatedValue);
                    setFieldValue(relatedIdField, newValue?.[relatedIdField] || '');
                }}
                value={options.find((option) => {
                    if (column.field === 'customer_name' || column.field === 'owner_name') {
                        return `${option.first_name} ${option.last_name}` === values[column.field];
                    } else if (column.field === 'trademark_name') {
                        return option.name_En === values[column.field];
                    } else if (column.field === 'service_name') {
                        return option.service_name === values[column.field];
                    } else if (column.field === 'user_name') {
                        //return option.user_id === loggedInUser.loggedInUserFullName.user_id;
                        return option.full_name === values[column.field];

                    } else {
                        return option.name === values[column.field];
                    }
                })}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={column.required ? `${t(column.headerName)} *` : t(column.headerName)} // Translate the label
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        value={values[column.field]}
                    />
                )}
            />
                <ErrorMessage name={column.field} component="div" style={{color: 'red'}}/>

            </div>
            <div style={{ flex: '5%' }}>
                <Tooltip title={`Add a new ${column.field.split('_')[0].charAt(0).toUpperCase() + column.field.split('_')[0].slice(1)}`}>
                    <IconButton
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            onAddNew(column.field); // Call the onAddNew callback with the column.field value
                        }}
                    >
                        <AddCircleOutlinedIcon />
                    </IconButton>
                </Tooltip>

        </div>
        </div>
    );
    const renderDatePicker = (column, values, handleChange, errors, touched) => {
        const field = column.field;
        const fieldValue = values[field];
        return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{width: '100%'}}>
                <DatePicker
                    name={column.field}
                    label={column.required ? `${t(column.headerName)} *` : t(column.headerName)}
                    margin="normal"
                    format='DD/MM/YYYY'
                    value={values[column.field] ? dayjs(values[column.field]) : null}
                    onChange={(date) => {
                        const isoDate = date ? dayjs(date).format() : null; // Use Day.js default ISO 8601 format
                        handleChangeAndBlur(handleChange, column.field, isoDate);
                    }}
                    error={touched[column.field] && !!errors[column.field]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={column.required ? `${t(column.headerName)} *` : t(column.headerName)}
                            margin="normal"
                            onChange={handleChange}
                            value={values[column.field] ? dayjs(values[column.field]).format('DD/MM/YYYY') : ''}
                        />
                    )}
                />
            </div>
        </LocalizationProvider>);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Formik initialValues={selectedData || initialValues} validationSchema={validationSchema}
                        onSubmit={onSubmit}>
                    {({handleChange, values, errors, touched, setFieldValue, handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {columnNames.map((column) => (
                                    <Grid item xs={12} key={column.field}>
                                        {column.type === 'TextField' && column.location !== 'Grid' && renderTextField(column, values, handleChange, errors, touched, column.readOnly || false)}
                                        {column.type === 'Password' && renderTextField(column, values, handleChange, errors, touched)}
                                        {column.type === 'combobox' && column.field === 'category_name' && renderAutocomplete(column, categoryOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'service_name' && renderAutocomplete(column, serviceOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'agency_name' && renderAutocomplete(column, agencyOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'owner_name' && renderAutocomplete(column, ownerOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'customer_name' && renderAutocomplete(column, customerOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'trademark_name' && renderAutocomplete(column, trademarkOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'combobox' && column.field === 'user_name' && renderAutocomplete(column, userOptions, values, setFieldValue, touched, errors, handleChange)}
                                        {column.type === 'DatePicker' && column.location !== 'Grid' && renderDatePicker(column, values, handleChange, errors, touched)}
                                        {column.type === 'Logo' && renderLogoInput(values, setFieldValue)}

                                    </Grid>
                                ))}
                            </Grid>
                            <DialogActions>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        {t("Save")}
                                    </Button>
                                </Box>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button onClick={onClose} color="secondary" variant="contained">
                                        {t("Cancel")}
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>

    );
};

export default CommonForm;
