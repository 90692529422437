import axios from 'axios';
import API_ENDPOINTS from '../config';

const InvoiceService = {
    async getInvoices() {
        try {
            const response = await axios.get(API_ENDPOINTS.INVOICE);
            return response.data;
        } catch (error) {
            console.error('Error fetching invoices:', error);
            throw error;
        }
    },

    async getInvoiceById(invoiceId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.INVOICE}/${invoiceId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching invoice with ID ${invoiceId}:`, error);
            throw error;
        }
    },

    async createInvoice(invoiceData) {
        console.log("invoiceData", invoiceData)
        try {
            const response = await axios.post(API_ENDPOINTS.INVOICE, invoiceData);
            return response.data;
        } catch (error) {
            console.error('Error creating invoice:', error);
            throw error;
        }
    },

    async updateInvoice(invoiceId, invoiceData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.INVOICE}/${invoiceId}`, invoiceData);
            return response.data;
        } catch (error) {
            console.error('Error updating invoice:', error);
            throw error;
        }
    },

    async deleteInvoice(invoiceId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.INVOICE}/${invoiceId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting invoice:', error);
            throw error;
        }
    }
};

export default InvoiceService;
