import React, {useState} from "react";
import {Menu, MenuItem, ProSidebar} from "react-pro-sidebar";
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {tokens} from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import {
    AppRegistrationOutlined,
    CategoryOutlined,
    CopyrightOutlined,
    DesignServicesOutlined,
    GroupsOutlined,
    ManageAccountsOutlined,
    PriceCheckOutlined,
    StorefrontOutlined,
    SupervisorAccountOutlined
} from "@mui/icons-material";

import {useTranslation} from 'react-i18next'; // Import useTranslation

const Item = ({title, to, icon, selected, setSelected}) => {
    const {t} = useTranslation(); // Get the translation function
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{t(title)}</Typography> {/* Use t to translate the title */}
            <Link to={to}/>
        </MenuItem>
    );
};

const Sidebar = () => {
    const {t} = useTranslation(); // Get the translation function
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{m: "10px 0 0 0"}}
                                >
                                    {t("Trademark")}
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 20px"}}
                        >
                            {t("Trademark Management")}
                        </Typography>
                        <Item
                            title="Trademark"
                            to="/trademark"
                            icon={<StorefrontOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Category"
                            to="/categories"
                            icon={<CategoryOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Registered Applications"
                            to="/registered"
                            icon={<AppRegistrationOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {/* Document
            <Item
                title="Documents"
                to="/contacts"
                icon={<DocumentScannerOutlined />}
                selected={selected}
                setSelected={setSelected}
            /> */}


                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 20px"}}
                        >
                            {t("Services and Pricing")}
                        </Typography>
                        <Item
                            title="Service"
                            to="/service"
                            icon={<DesignServicesOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Pricing"
                            to="/serviceprice"
                            icon={<PriceCheckOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Invoices"
                            to="/serviceinvoice"
                            icon={<ReceiptOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 20px"}}
                        >
                            {t("Business Entities")}
                        </Typography>

                        <Item
                            title="Agency"
                            to="/agency"
                            icon={<GroupsOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Owner"
                            to="/owner"
                            icon={<CopyrightOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Customer"
                            to="/customer"
                            icon={<SupervisorAccountOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="User"
                            to="/user"
                            icon={<ManageAccountsOutlined/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
