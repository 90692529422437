import axios from 'axios';
import API_ENDPOINTS from "../config";

const UserService = {
    async getAllUsers() {
        try {
            const response = await axios.get(API_ENDPOINTS.USER);
            return response.data;
        } catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    },

    async getUserById(userId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.USER}/${userId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching user with ID ${userId}:`, error);
            throw error;
        }
    },

    async createUser(userData) {
        try {
            const response = await axios.post(API_ENDPOINTS.USER, userData);
            return response.data;
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    },

    async updateUser(userId, userData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.USER}/${userId}`, userData);
            return response.data;
        } catch (error) {
            console.error(`Error updating user with ID ${userId}:`, error);
            throw error;
        }
    },

    async deleteUser(userId) {
        try {
            await axios.delete(`${API_ENDPOINTS.USER}/${userId}`);
        } catch (error) {
            console.error(`Error deleting user with ID ${userId}:`, error);
            throw error;
        }
    }
};

export default UserService;
