import axios from 'axios';
import API_ENDPOINTS from "../config";

const RegistrationService = {

    async getRegisterTrademark() {
        try {
            const response = await axios.get(API_ENDPOINTS.TRADEMARK_REGISTRATION);
            return response.data;
        } catch (error) {
            console.error('Error registering trademark:', error);
            throw error;
        }
    },

    async createRegisterTrademark(registerTrademarkData) {
        console.log("createRegisterTrademark", registerTrademarkData)
        try {
            const response = await axios.post(API_ENDPOINTS.TRADEMARK_REGISTRATION, registerTrademarkData);
            return response.data;
        } catch (error) {
            console.error('Error registering trademark:', error);
            throw error;
        }
    },

    async getRegisterTrademarkById(registerId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.TRADEMARK_REGISTRATION}/${registerId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching register trademark with ID ${registerId}:`, error);
            throw error;
        }
    },

    async updateRegisterTrademark(registerId, registerTrademarkData) {
        console.log("updateRegisterTrademark", registerTrademarkData)

        try {
            const response = await axios.put(`${API_ENDPOINTS.TRADEMARK_REGISTRATION}/${registerId}`, registerTrademarkData);
            return response.data;
        } catch (error) {
            console.error('Error updating register trademark:', error);
            throw error;
        }
    },

    async deleteRegisterTrademark(registerId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.TRADEMARK_REGISTRATION}/${registerId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting trademark:', error);
            throw error;
        }
    },
    async checkRegistrationNumberUniqueness(registrationNumber) {
        console.log("registrationNumber", registrationNumber)
        try {
            const response = await axios.get(`${API_ENDPOINTS.TRADEMARK_CHECK_REGISTRATION_NUMBER}/${registrationNumber}`);
            console.log("response.data", response);
            return response.data;
        } catch (error) {
            console.error('Error checking registration number uniqueness:', error);
            throw error;
        }
    },
};

export default RegistrationService;
