import axios from 'axios';
import API_ENDPOINTS from '../config';

const ServiceService = {
    async getServices() {
        try {
            const response = await axios.get(API_ENDPOINTS.SERVICE);
            return response.data;
        } catch (error) {
            console.error('Error fetching services:', error);
            throw error;
        }
    },

    async getServiceById(serviceId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.SERVICE}/${serviceId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching service with ID ${serviceId}:`, error);
            throw error;
        }
    },

    async createService(serviceData) {
        try {
            const response = await axios.post(API_ENDPOINTS.SERVICE, serviceData);
            return response.data;
        } catch (error) {
            console.error('Error creating service:', error);
            throw error;
        }
    },

    async updateService(serviceId, serviceData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.SERVICE}/${serviceId}`, serviceData);
            return response.data;
        } catch (error) {
            console.error('Error updating service:', error);
            throw error;
        }
    },

    async deleteService(serviceId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.SERVICE}/${serviceId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting service:', error);
            throw error;
        }
    }
};

export default ServiceService;
