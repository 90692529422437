import React, {useEffect} from 'react';
import {Box, Button, IconButton, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AgencyService from '../data/agencyService';
import columnNames from '../utlis/columnNames';
import validationSchemas from '../utlis/validationSchemas';
import getDataGridStyles from "../utlis/dataGridStyles";
import CommonForm from './Common/CommonForm';
import Header from "./Layout/header/Header";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import DeleteConfirmationDialog from "./Common/DeleteConfirmDialog";
import {tokens} from "../theme";
import useDataManagement from '../utlis/useDataManagement';
import {useTranslation} from 'react-i18next';

function Agency() {
    const {t} = useTranslation();
    const entityType = 'agency';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataGridStyle = getDataGridStyles(colors);

    const {
        data,
        selectedData,
        deleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleSave,
    } = useDataManagement(
        AgencyService.getAgencies,
        AgencyService.createAgency,
        AgencyService.updateAgency,
        AgencyService.deleteAgency,
        entityType
    );

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        ...columnNames.agency.map((column) => ({
            ...column,
            headerName: t(column.headerName), // Translate the column header
        })),
        {
            field: 'Actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header
                title={t("Agency")}
                subtitle={t("List of Agencies")}
            />
            <Box display="flex" justifyContent="end" mt="20px">
                <Button onClick={handleCreate} color="secondary" variant="contained">
                    {t("Add Agency")}
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={dataGridStyle}
            >
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />

                <CommonForm
                    open={isCardOpen}
                    onClose={handleCloseCard}
                    title="Agency Form"
                    initialValues={{}}
                    validationSchema={validationSchemas.agency}
                    onSubmit={handleSave}
                    columnNames={columnNames["agency"]}
                    selectedData={selectedData}
                />

                <DeleteConfirmationDialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    onConfirm={handleDeleteConfirmed}
                />

            </Box>
        </Box>
    );
}

export default Agency;
