import axios from 'axios';
import API_ENDPOINTS from "../config";

const AgencyService = {
    async getAgencies() {
        try {
            const response = await axios.get(API_ENDPOINTS.AGENCY);
            return response.data;
        } catch (error) {
            console.error('Error fetching agencies:', error);
            throw error;
        }
    },

    async getAgencyById(agencyId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.AGENCY}/${agencyId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching agency with ID ${agencyId}:`, error);
            throw error;
        }
    },


    async createAgency(agencyData) {
        try {
            const response = await axios.post(API_ENDPOINTS.AGENCY, agencyData);
            return response.data;
        } catch (error) {
            console.error('Error creating agency:', error);
            throw error;
        }
    },

    async updateAgency(agencyId, agencyData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.AGENCY}/${agencyId}`, agencyData);
            return response.data;
        } catch (error) {
            console.error('Error updating agency:', error);
            throw error;
        }
    },

    async deleteAgency(agencyId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.AGENCY}/${agencyId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting agency:', error);
            throw error;
        }
    }
};

export default AgencyService;
