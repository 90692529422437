import axios from 'axios';
import API_ENDPOINTS from '../config';

const ServicePriceService = {
    async getServicePrices() {
        try {
            const response = await axios.get(API_ENDPOINTS.SERVICEPRICE);
            return response.data;
        } catch (error) {
            console.error(`Error fetching prices:`, error);
            throw error;
        }
    },

    async getServicePriceById(servicePriceId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.SERVICEPRICE}/${servicePriceId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching price with ID ${servicePriceId}:`, error);
            throw error;
        }
    },

    async createServicePrice(priceData) {
        try {
            const response = await axios.post(API_ENDPOINTS.SERVICEPRICE, priceData);
            return response.data;
        } catch (error) {
            console.error('Error creating price:', error);
            throw error;
        }
    },

    async updateServicePrice(servicePriceId, priceData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.SERVICEPRICE}/${servicePriceId}`, priceData);
            return response.data;
        } catch (error) {
            console.error('Error updating price:', error);
            throw error;
        }
    },

    async deleteServicePrice(servicePriceId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.SERVICEPRICE}/${servicePriceId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting price:', error);
            throw error;
        }
    }
};

export default ServicePriceService;
