import React, {useCallback, useEffect, useState} from 'react';
import TrademarkService from "../data/trademarkService";
import {fetchCategories} from "../data/categoryService";
import {Box, Button, IconButton, Tooltip, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import columnNames from '../utlis/columnNames';
import validationSchemas from '../utlis/validationSchemas';
import getDataGridStyles from "../utlis/dataGridStyles";
import CommonForm from './Common/CommonForm';
import Header from "./Layout/header/Header";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import DeleteConfirmationDialog from "./Common/DeleteConfirmDialog";
import {tokens} from "../theme";
import useDataManagement from '../utlis/useDataManagement';
import ServiceService from "../data/serviceService";
import UserService from "../data/userService";
import InvoiceService from "../data/invoiceService";
import {formatDate, getEntityData} from "../utlis/dateUtils";
import {useTranslation} from 'react-i18next';
import ConfirmDialog from "./Common/ConfirmDialog";
import * as CategoryService from "../data/categoryService";
import OwnerService from "../data/ownerService";
import CustomerService from "../data/customerService";
import AgencyService from "../data/agencyService";

const Invoice = (loggedInUserFullName) => {
    const {t} = useTranslation();
    const entityType = 'service_invoice';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataGridStyle = getDataGridStyles(colors);
    const [trademarkOptions, seTrademarkOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [isTrademarkFormOpen, setTrademarkFormOpen] = useState(false);
    const [isCategoryFormOpen, setCategoryFormOpen] = useState(false);
    const [isUserFormOpen, setUserFormOpen] = useState(false);
    const [isServiceFormOpen, setServiceFormOpen] = useState(false);
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [agencyOptions, setAgencyOptions] = useState([]);

    const fetchTrademarkOptions = async () => {
        seTrademarkOptions(await TrademarkService.getTrademarks())
    };
    const fetchCategoryOptions = async () => {
        setCategoryOptions(await fetchCategories())
    };
    const fetchServiceOptions = async () => {
        setServiceOptions(await ServiceService.getServices())
    };
    const fetchUserOptions = async () => {
        setUserOptions(await UserService.getAllUsers())
    };

    const fetchOwnerOptions = async () => {
        setOwnerOptions(await OwnerService.getAllOwners())
    };
    const fetchCustomerOptions = async () => {
        setCustomerOptions(await CustomerService.getAllCustomers())
    };
    const fetchAgencyOptions = async () => {
        setAgencyOptions(await AgencyService.getAgencies())
    };

    const {
        data,
        selectedData,
        deleteDialogOpen,
        confirmDeleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleConfirm,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleConfirmDialogClose,
        handleConfirmDialog,
        handleSave,
    } = useDataManagement(
        InvoiceService.getInvoices,
        InvoiceService.createInvoice,
        InvoiceService.updateInvoice,
        InvoiceService.deleteInvoice,
        entityType
    );


    useEffect(() => {
        fetchData();
        fetchCategoryOptions();
        fetchTrademarkOptions();
        fetchServiceOptions();
        fetchUserOptions();
        fetchOwnerOptions();
        fetchCustomerOptions();
        fetchAgencyOptions();


    }, []);

    const handleFormSubmit = useCallback(
        async (formData, serviceFunction, setOpenStateFunction, fetchDataFunction) => {
            try {
                // Call the API to create the entity
                await serviceFunction(formData);

                // After successful submission, you can refresh the data and close the dialog
                fetchDataFunction(); // Refresh the data
                setOpenStateFunction(false); // Close the dialog
            } catch (error) {
                // Handle errors if needed
                console.error(`Error creating entity:`, error);
            }
        },
        []
    );
    const handleTrademarkFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                TrademarkService.createTrademark,
                setTrademarkFormOpen,
                fetchTrademarkOptions
            );
        },
        [setTrademarkFormOpen, fetchTrademarkOptions]
    );

    const handleCategoryFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                CategoryService.createCategory,
                setCategoryFormOpen,
                fetchCategoryOptions
            );
        },
        [setCategoryFormOpen, fetchCategoryOptions]
    );
    const handleUserFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                UserService.createUser,
                setUserFormOpen,
                fetchUserOptions
            );
        },
        [setUserFormOpen, fetchUserOptions]
    );
    const handleServiceFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                ServiceService.createService,
                setServiceFormOpen,
                fetchServiceOptions
            );
        },
        [setServiceFormOpen, fetchServiceOptions]
    );
    const handleAddNewForm = (field) => {
        // Determine which form to open based on the field value
        if (field === 'trademark_name') {
            setTrademarkFormOpen(true);
        } else if (field === 'category_name') {
            setCategoryFormOpen(true);

        } else if (field === 'user_name') {
            setUserFormOpen(true);

        } else if (field === 'service_name') {
            // Open Owner Form
            setServiceFormOpen(true);
        }
    };
    const columns = [
        ...columnNames.service_invoice
            .filter((column) => column.type !== 'hidden')
            .map((column) => ({
                ...column,
                headerName: t(column.headerName),
            })),
        {
            field: 'Actions',
            headerName: t('Actions'),
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <Tooltip title="Pay Invoice">
                        <IconButton onClick={() => handleConfirm(params.row)}>
                            <RequestQuoteOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header
                title={t("Invoices")}
                subtitle={t("List of Invoices")}
            />
            <Box display="flex" justifyContent="end" mt="20px">
                <Button onClick={handleCreate} color="secondary" variant="contained">
                    {t("Add Invoice")}
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={dataGridStyle}
            >
                <DataGrid
                    rows={formatDate(data, ['invoice_date_generated', 'invoice_due_date'])}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />

                {isTrademarkFormOpen && (
                    <CommonForm
                        open={isTrademarkFormOpen}
                        onClose={() => setTrademarkFormOpen(false)}
                        title={"Trademark Form"}
                        initialValues={{}}
                        validationSchema={validationSchemas.trademark} // Replace with the appropriate validation schema
                        onSubmit={handleTrademarkFormSubmit}
                        columnNames={columnNames["trademark"]}
                        selectedData={selectedData}
                        categoryOptions={categoryOptions}
                        agencyOptions={agencyOptions}
                        ownerOptions={ownerOptions}
                        customerOptions={customerOptions}
                        serviceOptions={serviceOptions}
                        trademarkOptions={trademarkOptions}
                        loggedInUser={loggedInUserFullName}
                        userOptions={userOptions}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}

                {isCategoryFormOpen && (
                    <CommonForm
                        open={isCategoryFormOpen}
                        onClose={() => setCategoryFormOpen(false)}
                        title="Category Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.category} // Replace with the appropriate validation schema
                        onSubmit={handleCategoryFormSubmit}
                        columnNames={columnNames["category"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}

                {isUserFormOpen && (
                    <CommonForm
                        open={isUserFormOpen}
                        onClose={() => setUserFormOpen(false)}
                        title="User Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.user}
                        onSubmit={handleUserFormSubmit}
                        columnNames={columnNames["user"]}
                        selectedData={selectedData}
                    />
                )}

                {isServiceFormOpen && (
                    <CommonForm
                        open={isServiceFormOpen}
                        onClose={() => setServiceFormOpen(false)}
                        title="Service Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.service}
                        onSubmit={handleServiceFormSubmit}
                        columnNames={columnNames["service"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}

                <CommonForm
                    open={isCardOpen}
                    onClose={handleCloseCard}
                    title="Invoice Form"
                    initialValues={{}}
                    validationSchema={validationSchemas.service_invoice}
                    onSubmit={handleSave}
                    columnNames={columnNames["service_invoice"]}
                    selectedData={selectedData}
                    categoryOptions={categoryOptions}
                    trademarkOptions={trademarkOptions}
                    loggedInUser={loggedInUserFullName}
                    userOptions={userOptions}
                    serviceOptions={serviceOptions}
                    onAddNew={handleAddNewForm} // Pass the callback function

                />

                <DeleteConfirmationDialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    onConfirm={handleDeleteConfirmed}
                />
                <ConfirmDialog
                    open={confirmDeleteDialogOpen}
                    onClose={handleConfirmDialogClose}
                    onConfirm={handleConfirmDialog}
                />
            </Box>
        </Box>
    );
};

export default Invoice;
