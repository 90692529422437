import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next'; // Import the useTranslation hook

const DeleteConfirmDialog = ({open, onClose, onConfirm}) => {
    const {t} = useTranslation(); // Get the translation function

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('Delete Confirm')}</DialogTitle> {/* Translate the dialog title */}
            <DialogContent>
                {t('Are you sure you want to delete this item?')} {/* Translate the dialog content */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {t('Cancel')} {/* Translate the "Cancel" button label */}
                </Button>
                <Button onClick={onConfirm} color="error">
                    {t('Delete')} {/* Translate the "Delete" button label */}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmDialog;
