import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';

const ConfirmDialog = ({open, onClose, onConfirm}) => {
    const {t} = useTranslation(); // Get the translation function

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('Confirm Payment')}</DialogTitle> {/* Translate the dialog title */}
            <DialogContent>
                {t('Are you absolutely certain that you want to make the payment for this invoice and then close it?')} {/* Translate the dialog content */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    {t('Cancel')} {/* Translate the "Cancel" button label */}
                </Button>
                <Button onClick={onConfirm} color="secondary">
                    {t('Confirm')} {/* Translate the "Delete" button label */}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
