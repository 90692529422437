import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detect user's language
    .use(initReactI18next) // Bind i18n to React
    .init({
        resources: {
            en: {
                translation: require('./locales/en.json'), // English translations
            },
            ar: {
                translation: require('./locales/ar.json'), // Arabic translations
            },
        },
        lng: 'ar', // Set Arabic as the default language
        fallbackLng: 'en', // Fallback to English if user's language is not available
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);
