import {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Topbar from "./components/Layout/topbar/Topbar";
import Sidebar from "./components/Layout/sidebar/Sidebar";
import Dashboard from "./components/Dashboard";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ColorModeContext, useMode} from "./theme";
import Category from "./components/Category";
import Trademark from "./components/Trademark";
import Agency from "./components/Agency";
import Customer from "./components/Customer";
import User from "./components/User";
import Owner from "./components/Owner";
import Service from "./components/Service";
import ServicePrice from "./components/ServicePrice";
import Invoice from "./components/Inovice";
import Login from './components/Login';
import BackupManagement from "./components/BackupManagement";
import RegisteredApplication from "./components/RegisteredApplicaion";
import LoadingIndicator from "./components/Common/LoadingIndicator";

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedInUserFullName, setLoggedInUserFullName] = useState(""); // State to hold the logged-in user's full name
    const [isAuthenticationChecked, setIsAuthenticationChecked] = useState(false);

    const handleLogin = ({user_id, full_name, rememberMe}) => {
        setLoggedIn(true);
        setLoggedInUserFullName(full_name);

        // Store authentication information if "Remember Me" is checked
        if (rememberMe) {
            localStorage.setItem("authToken", "yourAuthTokenHere");
        }
    };

    const handleLogout = () => {
        setLoggedIn(false);
        localStorage.removeItem("authToken");

    };

    useEffect(() => {
        // Check if an authentication token exists in localStorage
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
            // Set the user as logged in
            setLoggedIn(true);
        }

        setIsAuthenticationChecked(true); // Indicate that the authentication check is complete
    }, []);

    if (!isAuthenticationChecked) {
        // Show a loading indicator while checking authentication
        return <LoadingIndicator/>;
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div className="app">
                    {loggedIn ? (
                        <>

                            <Sidebar isSidebar={isSidebar}/>
                            <main className="content">
                                <Topbar setIsSidebar={setIsSidebar} onLogout={handleLogout} loggedIn={loggedIn}/>
                                <Routes>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="/categories" element={<Category/>}/>
                                    <Route path="/trademark"
                                           element={<Trademark loggedInUserFullName={loggedInUserFullName}/>}/>
                                    <Route path="/agency" element={<Agency/>}/>
                                    <Route path="/customer" element={<Customer/>}/>
                                    <Route path="/user" element={<User/>}/>
                                    <Route path="/owner" element={<Owner/>}/>
                                    <Route path="/service" element={<Service/>}/>
                                    <Route path="/serviceprice" element={<ServicePrice/>}/>
                                    <Route path="/serviceinvoice"
                                           element={<Invoice loggedInUserFullName={loggedInUserFullName}/>}/>
                                    <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
                                    <Route path="/backup-import" element={<BackupManagement/>}/>
                                    <Route path="/registered" element={<RegisteredApplication/>}/>

                                </Routes>
                            </main>
                        </>

                    ) : (
                        <Login onLogin={handleLogin}/>
                    )}
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
