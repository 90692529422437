import {Box, IconButton, useTheme} from "@mui/material";
import {useContext, useState} from "react";
import {ColorModeContext, tokens} from "../../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BackupIcon from '@mui/icons-material/Backup';
import BackupManagement from "../../BackupManagement";
import LanguageIcon from '@mui/icons-material/Language';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import {useTranslation} from 'react-i18next';

const Topbar = ({setIsSidebar, loggedIn, onLogout}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [backupManagementOpen, setBackupManagementOpen] = useState(false);
    const {i18n} = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'ar' : 'en';
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    const handleBackupManagementOpen = () => {
        setBackupManagementOpen(true);
    };

    const handleBackupManagementClose = () => {
        setBackupManagementOpen(false);
    };
    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={toggleLanguage}>
                    {currentLanguage === 'ar' ? <LanguageIcon/> : <AbcOutlinedIcon/>}

                </IconButton>

                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon/>
                    ) : (
                        <LightModeOutlinedIcon/>
                    )}
                </IconButton>

                <IconButton
                    color="inherit"
                    onClick={handleBackupManagementOpen}
                    aria-label="backup"
                >
                    <BackupIcon/>
                </IconButton>

                {loggedIn && (
                    <IconButton
                        color="inherit"
                        aria-label="logout"
                        edge="end"
                        onClick={onLogout}
                        sx={{ml: "auto"}}
                    >
                        <PersonOutlinedIcon/>
                    </IconButton>
                )}
            </Box>
            {backupManagementOpen && (
                <BackupManagement onClose={handleBackupManagementClose}/>
            )}
        </Box>

    );
};

export default Topbar;
