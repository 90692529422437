import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Snackbar,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import Header from "./Layout/header/Header";
import {BackupTableSharp} from "@mui/icons-material";
import {importBackup, takeBackup} from "../data/backeupService";
import {useTranslation} from "react-i18next";

const BackupManagement = ({onClose}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [isTakingBackup, setIsTakingBackup] = useState(false);
    const [isImportingBackup, setIsImportingBackup] = useState(false);
    const [importedBackupFile, setImportedBackupFile] = useState(null);

    // Function to show the Snackbar
    const showSnackbar = (message, severity) => {
        const translatedMessage = t(message); // Translate the message
        setSnackbarMessage(translatedMessage);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    // Function to handle taking a backup
    const handleTakeBackup = async () => {
        setIsTakingBackup(true);
        try {
            const message = await takeBackup();
            showSnackbar(message, "success");
        } catch (error) {
            showSnackbar("Failed to take backup", "error");
        } finally {
            setIsTakingBackup(false);
        }
    };

    // Function to handle importing a backup
    const handleImportBackup = async () => {
        setIsImportingBackup(true);
        try {
            if (importedBackupFile) {
                const message = await importBackup(importedBackupFile);
                showSnackbar(message, "success");
            } else {
                showSnackbar("No backup file selected for import", "error");
            }
        } catch (error) {
            showSnackbar("Failed to import backup", "error");
        } finally {
            setIsImportingBackup(false);
        }
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    };

    const titleStyle = {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    };

    const inputStyle = {
        width: "100%",
        marginBottom: theme.spacing(2),
    };

    const buttonStyle = {
        marginTop: theme.spacing(1),
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                <Header title={t("Trademark Dashboard")}/>
                <Typography style={titleStyle} variant="h5">
                    {t("Backup Management")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{mt: 3, mb: 2}}
                    style={buttonStyle}
                    onClick={handleTakeBackup}
                    disabled={isTakingBackup}
                    startIcon={<BackupIcon/>}
                >
                    {isTakingBackup ? t("Taking Backup...") : t("Take Backup")}
                </Button>
                <Divider style={{margin: `${theme.spacing(2)} 0`}}/>
                <TextField
                    style={inputStyle}
                    type="file"
                    accept=".sql" // Allow only SQL files
                    onChange={(e) => setImportedBackupFile(e.target.files[0])}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{mt: 3, mb: 2}}
                    style={buttonStyle}
                    onClick={handleImportBackup}
                    disabled={isImportingBackup}
                    startIcon={<BackupTableSharp/>}
                >
                    {isImportingBackup ? t("Importing Backup...") : t("Import Backup")}
                </Button>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                >
                    <Box>
                        <Typography>{snackbarMessage}</Typography>
                    </Box>
                </Snackbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BackupManagement;
