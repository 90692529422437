export const API_BASE_URL = 'https://api-trademark.onrender.com/api';
export const API_ENDPOINTS = {
    CATEGORIES: `${API_BASE_URL}/category`,
    TRADEMARKS: `${API_BASE_URL}/trademark`,
    TRADEMARKPRICESERVIVE: `${API_BASE_URL}/trademark/servicecustomerprice`,
    AGENCY: `${API_BASE_URL}/agency`,
    OWNER: `${API_BASE_URL}/owner`,
    CUSTOMER: `${API_BASE_URL}/customer`,
    USER: `${API_BASE_URL}/user`,
    SERVICE: `${API_BASE_URL}/service`,
    SERVICEPRICE: `${API_BASE_URL}/serviceprice`,
    INVOICE: `${API_BASE_URL}/serviceinvoice`,
    TRADEMARK_REGISTRATION: `${API_BASE_URL}/registration`,
    TRADEMARK_CHECK_REGISTRATION_NUMBER: `${API_BASE_URL}/registration/check-registration-unique`,
    TAKE_BACKUP: `${API_BASE_URL}/backup/takebackup`,
    IMPORT_BACKUP: `${API_BASE_URL}/backup/importbackup`,

};

export default API_ENDPOINTS;