import axios from 'axios';
import API_ENDPOINTS from "../config";

export const fetchCategories = async () => {
    try {
        const response = await axios.get(API_ENDPOINTS.CATEGORIES);
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

export const getCategoryById = async (categoryId) => {
    try {
        const response = await axios.get(`${API_ENDPOINTS.CATEGORIES}/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching category ${categoryId}:`, error);
        throw error;
    }
};

export const createCategory = async (newCategory) => {
    try {
        const response = await axios.post(API_ENDPOINTS.CATEGORIES, newCategory);
        return response.data;
    } catch (error) {
        console.error('Error creating category:', error);
        throw error;
    }
};

export const updateCategory = async (categoryId, updatedCategory) => {
    try {
        const response = await axios.put(`${API_ENDPOINTS.CATEGORIES}/${categoryId}`, updatedCategory);
        return response.data;
    } catch (error) {
        console.error(`Error updating category ${categoryId}:`, error);
        throw error;
    }
};

export const deleteCategory = async (categoryId) => {
    try {
        const response = await axios.delete(`${API_ENDPOINTS.CATEGORIES}/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting category ${categoryId}:`, error);
        throw error;
    }
};
