import React, {useCallback, useEffect, useState} from 'react';
import TrademarkService from "../data/trademarkService";
import AgencyService from '../data/agencyService';
import CustomerService from '../data/customerService';
import OwnerService from '../data/ownerService';
import {fetchCategories} from "../data/categoryService";
import {Box, Button, IconButton, Tooltip, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import columnNames from '../utlis/columnNames';
import getDataGridStyles from "../utlis/dataGridStyles";
import CommonForm from './Common/CommonForm';
import Header from "./Layout/header/Header";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import DeleteConfirmationDialog from "./Common/DeleteConfirmDialog";
import {tokens} from "../theme";
import useDataManagement from '../utlis/useDataManagement';
import {formatDate, getEntityData} from "../utlis/dateUtils";
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import ServiceService from "../data/serviceService";
import UserService from "../data/userService";
import {AppRegistrationOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import validationSchemas from "../utlis/validationSchemas";
import * as CategoryService from "../data/categoryService";

const Trademark = (loggedInUserFullName) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dataGridStyle = getDataGridStyles(colors);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [agencyOptions, setAgencyOptions] = useState([]);
    const [entityType, setEntityType] = useState("trademark");
    const [serviceOptions, setServiceOptions] = useState([]);
    const [trademarkOptions, seTrademarkOptions] = useState([]);
    const [userOptions, seUserOptions] = useState([]);
    const [isAgencyFormOpen, setAgencyFormOpen] = useState(false);
    const [isCategoryFormOpen, setCategoryFormOpen] = useState(false);
    const [isCustomerFormOpen, setCustomerFormOpen] = useState(false);
    const [isOwnerFormOpen, setOwnerFormOpen] = useState(false);

    const fetchTrademarkOptions = async () => {
        seTrademarkOptions(await TrademarkService.getTrademarks())
    };
    const fetchCategoryOptions = async () => {
        setCategoryOptions(await fetchCategories())
    };
    const fetchOwnerOptions = async () => {
        setOwnerOptions(await OwnerService.getAllOwners())
    };
    const fetchCustomerOptions = async () => {
        setCustomerOptions(await CustomerService.getAllCustomers())
    };
    const fetchAgencyOptions = async () => {
        setAgencyOptions(await AgencyService.getAgencies())
    };
    const fetchServiceOptions = async () => {
        setServiceOptions(await ServiceService.getServices())
    };
    const fetchUserOptions = async () => {
        seUserOptions(await UserService.getAllUsers())
    };


    const {
        data,
        selectedData,
        deleteDialogOpen,
        isCardOpen,
        fetchData,
        handleEdit,
        handleCreate,
        handleCloseCard,
        handleDelete,
        handleDeleteDialogClose,
        handleDeleteConfirmed,
        handleSave,
    } = useDataManagement(
        TrademarkService.getTrademarks,
        getEntityData(entityType, "createService"),
        getEntityData(entityType, "updateService"),
        TrademarkService.deleteTrademark,
        entityType
    );

    useEffect(() => {
        fetchData();
        fetchCategoryOptions();
        fetchOwnerOptions();
        fetchCustomerOptions();
        fetchAgencyOptions();
        fetchServiceOptions();
        fetchTrademarkOptions();
        fetchUserOptions();

    }, []);

    const handleFormSubmit = useCallback(
        async (formData, serviceFunction, setOpenStateFunction, fetchDataFunction) => {
            try {
                // Call the API to create the entity
                await serviceFunction(formData);

                // After successful submission, you can refresh the data and close the dialog
                fetchDataFunction(); // Refresh the data
                setOpenStateFunction(false); // Close the dialog
            } catch (error) {
                // Handle errors if needed
                console.error(`Error creating entity:`, error);
            }
        },
        []
    );

    const handleCategoryFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                CategoryService.createCategory,
                setCategoryFormOpen,
                fetchCategoryOptions
            );
        },
        [setCategoryFormOpen, fetchCategoryOptions]
    );

    const handleCustomerFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                CustomerService.createCustomer,
                setCustomerFormOpen,
                fetchCustomerOptions
            );
        },
        [setCustomerFormOpen, fetchCustomerOptions]
    );

    const handleOwnerFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                OwnerService.createOwner,
                setOwnerFormOpen,
                fetchOwnerOptions
            );
        },
        [setOwnerFormOpen, fetchOwnerOptions]
    );

    const handleAgencyFormSubmit = useCallback(
        (formData) => {
            handleFormSubmit(
                formData,
                AgencyService.createAgency,
                setAgencyFormOpen,
                fetchAgencyOptions
            );
        },
        [setAgencyFormOpen, fetchAgencyOptions]
    );


    const handleAddNewForm = (field) => {
        // Determine which form to open based on the field value
        if (field === 'agency_name') {
            // Open Agency Form
            setAgencyFormOpen(true);
        } else if (field === 'category_name') {
            // Open Category Form
            console.log("Category");
            setCategoryFormOpen(true);

        } else if (field === 'customer_name') {
            // Open Customer Form
            console.log("Customer");
            setCustomerFormOpen(true);

        } else if (field === 'owner_name') {
            // Open Owner Form
            console.log("Owner");
            setOwnerFormOpen(true);

        }
    };

    const columns = [
        ...columnNames.trademark
            .filter((column) => column.type !== 'hidden')
            .map((column) => ({
                ...column,
                headerName: t(column.headerName),
            })),
        {
            field: 'Actions',
            headerName: t('Actions'),
            flex: 1.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <Tooltip title="Registration">
                        <IconButton onClick={() => {
                            setEntityType("registration");
                            handleEdit(params.row);
                        }}>
                            <AppRegistrationOutlined/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add Invoice">

                        <IconButton onClick={() => {
                            setEntityType("service_invoice");
                            handleEdit(params.row);
                        }}>
                            <InputOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Trademark">
                        <IconButton onClick={() => {
                            setEntityType("trademark");
                            handleEdit(params.row)
                        }}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Trademark">
                        <IconButton onClick={() => {
                            setEntityType("trademark");
                            handleDelete(params.row)
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <Box m="20px">
            <Header
                title={t("Trademark")}
                subtitle={t("List of Trademarks")}
            />
            <Box display="flex" justifyContent="end" mt="20px">
                <Button color="secondary" variant="contained"
                        onClick={() => {
                            setEntityType("trademark");
                            handleCreate()
                        }}
                >
                    {t("Add Trademark")}
                </Button>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={dataGridStyle}
            >

                <DataGrid
                    rows={formatDate(data, ['application_date'])}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />

                {isAgencyFormOpen && (
                    <CommonForm
                        open={isAgencyFormOpen}
                        onClose={() => setAgencyFormOpen(false)}
                        title="Agency Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.agency}
                        onSubmit={handleAgencyFormSubmit} // Use it here
                        columnNames={columnNames["agency"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}
                {isCategoryFormOpen && (
                    <CommonForm
                        open={isCategoryFormOpen}
                        onClose={() => setCategoryFormOpen(false)}
                        title="Category Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.category} // Replace with the appropriate validation schema
                        onSubmit={handleCategoryFormSubmit}
                        columnNames={columnNames["category"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}
                {isCustomerFormOpen && (
                    <CommonForm
                        open={isCustomerFormOpen}
                        onClose={() => setCustomerFormOpen(false)}
                        title="Customer Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.customer} // Replace with the appropriate validation schema
                        onSubmit={handleCustomerFormSubmit}
                        columnNames={columnNames["customer"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}

                {isOwnerFormOpen && (
                    <CommonForm
                        open={isOwnerFormOpen}
                        onClose={() => setOwnerFormOpen(false)}
                        title="Owner Form"
                        initialValues={{}}
                        validationSchema={validationSchemas.owner} // Replace with the appropriate validation schema
                        onSubmit={handleOwnerFormSubmit}
                        columnNames={columnNames["owner"]}
                        selectedData={selectedData}
                        onAddNew={handleAddNewForm} // Pass the callback function
                    />
                )}


                <CommonForm
                    open={isCardOpen}
                    onClose={handleCloseCard}
                    title={getEntityData(entityType, "title")}
                    initialValues={{}}
                    validationSchema={getEntityData(entityType, "schema")}
                    onSubmit={handleSave}
                    columnNames={getEntityData(entityType, "columns")}
                    selectedData={selectedData}
                    categoryOptions={categoryOptions}
                    agencyOptions={agencyOptions}
                    ownerOptions={ownerOptions}
                    customerOptions={customerOptions}
                    serviceOptions={serviceOptions}
                    trademarkOptions={trademarkOptions}
                    loggedInUser={loggedInUserFullName}
                    userOptions={userOptions}
                    onAddNew={handleAddNewForm} // Pass the callback function

                />

                <DeleteConfirmationDialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    onConfirm={handleDeleteConfirmed}
                />
            </Box>
        </Box>
    );
};

export default Trademark;
