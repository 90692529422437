import axios from 'axios';
import API_ENDPOINTS from "../config";

const TrademarkService = {
    async getTrademarks() {
        try {
            const response = await axios.get(API_ENDPOINTS.TRADEMARKS);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async getTrademarkById(trademarkId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.TRADEMARKS}/${trademarkId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async getPricesByServiceIdAndCustomerId(customerId, serviceId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.TRADEMARKPRICESERVIVE}/${customerId}/${serviceId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async createTrademark(trademarkData) {
        try {
            const response = await axios.post(API_ENDPOINTS.TRADEMARKS, trademarkData);
            return response.data;
        } catch (error) {
            throw error;
        }
    },


    async updateTrademark(trademarkId, updatedData) {
        console.log("trademarkId", trademarkId);
        console.log("updatedData", updatedData);
        try {
            const response = await axios.put(`${API_ENDPOINTS.TRADEMARKS}/${trademarkId}`, updatedData);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async deleteTrademark(trademarkId) {
        try {
            const response = await axios.delete(`${API_ENDPOINTS.TRADEMARKS}/${trademarkId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default TrademarkService;
