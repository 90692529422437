import React from "react";

const columnNames = {
    agency: [
        {field: 'name', headerName: 'Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'phone', headerName: 'Phone', align: 'left', flex: 1, type: 'TextField'},
        {field: 'address', headerName: 'Address', align: 'left', flex: 1, type: 'TextField'},
        {field: 'country', headerName: 'Country', align: 'left', flex: 1, type: 'TextField'},
        {field: 'city', headerName: 'City', align: 'left', flex: 1, type: 'TextField'},
        {field: 'email', headerName: 'Email', align: 'left', flex: 1, type: 'TextField'},
    ],
    category: [
        {field: 'name', headerName: 'Name', align: 'left', flex: 5, type: 'TextField'},
        {field: 'description', headerName: 'Description', align: 'left', flex: 5, type: 'TextField'},
    ],
    customer: [
        {field: 'first_name', headerName: 'First Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'last_name', headerName: 'Last Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'phone', headerName: 'Phone', align: 'left', flex: 1, type: 'TextField'},
        {field: 'country', headerName: 'Country', align: 'left', flex: 1, type: 'TextField'},
        {field: 'city', headerName: 'City', align: 'left', flex: 1, type: 'TextField'},
        {field: 'address', headerName: 'Address', align: 'left', flex: 1, type: 'TextField'},
        {field: 'email', headerName: 'Email', align: 'left', flex: 1, type: 'TextField'},
    ],
    document: [
        {field: 'document_content', headerName: 'Document Content', align: 'left', flex: 1, type: 'TextField'},
        {field: 'document_id', headerName: 'Document ID', align: 'left', flex: 1, type: 'TextField'},
        {field: 'document_name', headerName: 'Document Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'document_type', headerName: 'Document Type', align: 'left', flex: 1, type: 'TextField'},
        {field: 'trademark_id', headerName: 'Trademark ID', align: 'left', flex: 1, type: 'TextField'},
        {field: 'uploaded_at', headerName: 'Uploaded At', align: 'left', flex: 1, type: 'TextField'},
    ],
    owner: [
        {field: 'first_name', headerName: 'First Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'last_name', headerName: 'Last Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'phone', headerName: 'Phone', align: 'left', flex: 1, type: 'TextField'},
        {field: 'country', headerName: 'Country', align: 'left', flex: 1, type: 'TextField'},
        {field: 'city', headerName: 'City', align: 'left', flex: 1, type: 'TextField'},
        {field: 'address', headerName: 'Address', align: 'left', flex: 1, type: 'TextField'},
        {field: 'email', headerName: 'Email', align: 'left', flex: 1, type: 'TextField'},
    ],
    registration: [
        {field: 'registration_id', headerName: 'Registration ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'trademark_id', headerName: 'Trademark ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'category_id', headerName: 'Category ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'trademark_name', headerName: 'Trademark', align: 'left', flex: 1, type: 'combobox'},
        {field: 'category_name', headerName: 'Category', align: 'left', flex: 1, type: 'combobox'},
        {field: 'registration_number', headerName: 'Registration Number', align: 'left', flex: 1, type: 'TextField'},
        {field: 'registration_date', headerName: 'Registration Date', align: 'left', flex: 1, type: 'DatePicker'},
    ],
    role: [
        {field: 'description', headerName: 'Description', align: 'left', flex: 1, type: 'TextField'},
        {field: 'name', headerName: 'Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'role_id', headerName: 'Role ID', align: 'left', flex: 1, type: 'TextField'},
    ],
    service: [
        {field: 'service_name', headerName: 'Service Name', align: 'left', flex: 5, type: 'TextField'},
        {field: 'description', headerName: 'Description', align: 'left', flex: 5, type: 'TextField'},
    ],
    service_invoice: [
        {field: 'user_id', headerName: 'User ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'service_invoice_id', headerName: 'Invoice Number', align: 'left', flex: 1, type: 'TextField', readOnly: true, location: 'Grid'},
        {field: 'trademark_name', headerName: 'Trademark', align: 'left', flex: 1, type: 'combobox'},
        {field: 'category_name', headerName: 'Category', align: 'left', flex: 1, type: 'combobox'},
        {field: 'user_name', headerName: 'User', align: 'left', flex: 1, type: 'combobox'},
        {field: 'service_name', headerName: 'Service', align: 'left', flex: 1, type: 'combobox'},
        {field: 'amountLUD', headerName: 'Amount (LUD)', align: 'left', flex: 1, type: 'TextField'},
        {field: 'amountUSD', headerName: 'Amount (USD)', align: 'left', flex: 1, type: 'TextField'},
        {
            field: 'invoice_date_generated',
            headerName: 'Invoice Date Generated',
            align: 'left',
            flex: 1,
            type: 'DatePicker'
        },
        {
            field: 'invoice_due_date',
            headerName: 'Invoice Due Date',
            align: 'left',
            flex: 1,
            type: 'DatePicker',
            location: 'Grid'
        },
        {field: 'trademark_id', headerName: 'Trademark ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'category_id', headerName: 'Category ID', align: 'left', flex: 1, type: 'hidden'},
        {field: 'service_id', headerName: 'Service ID', align: 'left', flex: 1, type: 'hidden'},

    ],
    service_price: [
        {field: 'service_id', headerName: 'Service', align: 'left', flex: 1, type: 'hidden'},
        {field: 'customer_id', headerName: 'Customer', align: 'left', flex: 1, type: 'hidden'},
        {field: 'service_name', headerName: 'Service', align: 'left', flex: 1, type: 'combobox'},
        {field: 'customer_name', headerName: 'Customer', align: 'left', flex: 1, type: 'combobox'},
        {field: 'priceLUD', headerName: 'Price (LUD)', align: 'left', flex: 1, type: 'TextField'},
        {field: 'priceUSD', headerName: 'Price (USD)', align: 'left', flex: 1, type: 'TextField'},
    ],
    trademark: [
        {field: 'name_En', headerName: 'Name (En)', align: 'left', flex: 1, type: 'TextField'},
        {field: 'name_Ar', headerName: 'Name (Ar)', align: 'left', flex: 1, type: 'TextField'},
        {field: 'address', headerName: 'Address', align: 'left', flex: 1, type: 'TextField'},
        {field: 'agency_name', headerName: 'Agency', align: 'left', flex: 1, type: 'combobox'},
        {field: 'category_name', headerName: 'Category', align: 'left', flex: 1, type: 'combobox'},
        {field: 'customer_name', headerName: 'Customer', align: 'left', flex: 1, type: 'combobox'},
        {field: 'owner_name', headerName: 'Owner', align: 'left', flex: 1, type: 'combobox'},
        {field: 'logo', headerName: 'Logo', align: 'center', flex: 1, type: 'Logo',
            renderCell: (params) => {
                if (!params.value) {
                    return 'No Logo';
                }
                return (
                    <img
                        src={params.value}
                        alt="Logo"
                        style={{maxWidth: '50px'}}
                    />
                );
            },
        },
        {field: 'description', headerName: 'Description', align: 'left', flex: 1, type: 'TextField'},
        {field: 'application_date', headerName: 'Application Date', align: 'left', flex: 1, type: 'DatePicker'},
        {field: 'category_id', headerName: 'category_id', align: 'left', flex: 1, type: 'hidden'},
        {field: 'agency_id', headerName: 'agency_id', align: 'left', flex: 1, type: 'hidden'},
        {field: 'owner_id', headerName: 'owner_id', align: 'left', flex: 1, type: 'hidden'},
        {field: 'customer_id', headerName: 'customer_id', align: 'left', flex: 1, type: 'hidden'},

    ],
    user: [
        {field: 'full_name', headerName: 'Full Name', align: 'left', flex: 1, type: 'TextField'},
        {field: 'username', headerName: 'Username', align: 'left', flex: 1, type: 'TextField'},
        {field: 'email', headerName: 'Email', align: 'left', flex: 1, type: 'TextField'},
        {field: 'password', headerName: 'Password', align: 'left', flex: 1, type: 'Password'},
    ],
    user_role: [
        {field: 'role_id', headerName: 'Role ID', align: 'left', flex: 1, type: 'TextField'},
        {field: 'user_id', headerName: 'User ID', align: 'left', flex: 1, type: 'TextField'},
        {field: 'user_role_id', headerName: 'User Role ID', align: 'left', flex: 1, type: 'TextField'},
    ],
};

export default columnNames;
