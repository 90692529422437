import {Box, useTheme} from "@mui/material";
import {tokens} from "../theme";
import {useTranslation} from "react-i18next";


const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                HEADER
            </Box>
        </Box>
    );
};

export default Dashboard;
