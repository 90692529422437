import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, Container, CssBaseline, TextField, Typography,} from "@mui/material";
import Header from "./Layout/header/Header";
import UserService from "../data/userService";
import {useTranslation} from 'react-i18next';

const Login = ({onLogin}) => {
    const {t} = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false); // State for "Remember Me" checkbox
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null); // New state for error message

    useEffect(() => {
        async function fetchData() {
            const usersData = await UserService.getAllUsers();
            setUsers(usersData);
        }

        fetchData();
    }, []);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    const handleLogin = () => {
        // Find the user with the entered username
        const user = users.find((user) => user.username === username);

        if (!username || !password) {
            setError("Please enter both username and password.");
        } else if (!user || user.password !== password) {
            setError("Incorrect username or password.");
        } else {
            setError(null); // Clear any previous errors
            const user_id = user.user_id;
            const full_name = user.full_name;
            onLogin({user_id, full_name, rememberMe}); // Pass rememberMe value to the parent component
        }
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                }}
            >
                <Header /*title={t("Trademark Dashboard")}*/ />
                <Typography
                    component="h1"
                    variant="h5"
                    color="secondary"
                    sx={{fontWeight: "bold"}}
                >
                    {t("Log in")}
                </Typography>
                {error && (
                    <Typography variant="body2" color="error" sx={{my: 1}}>
                        {error}
                    </Typography>
                )}
                <Box component="form" noValidate sx={{mt: 1}}>
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={t("UserName")}
                        name="username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("Password")}
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Box sx={{display: 'flex', alignItems: 'center', ml: -1.5}}> {/* Add ml: -1 */}
                        <Checkbox
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                            color="secondary"
                        />
                        <Typography variant="body2">{t("Remember Me")}</Typography>
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{mt: 3, mb: 2}}
                        onClick={handleLogin}
                    >
                        {t("Log In")}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
