import axios from 'axios';
import API_ENDPOINTS from "../config";

const CustomerService = {
    async getAllCustomers() {
        try {
            const response = await axios.get(API_ENDPOINTS.CUSTOMER);
            return response.data;
        } catch (error) {
            console.error('Error fetching customers:', error);
            throw error;
        }
    },

    async getCustomerById(customerId) {
        try {
            const response = await axios.get(`${API_ENDPOINTS.CUSTOMER}/${customerId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching customer with ID ${customerId}:`, error);
            throw error;
        }
    },

    async createCustomer(customerData) {
        try {
            const response = await axios.post(API_ENDPOINTS.CUSTOMER, customerData);
            return response.data;
        } catch (error) {
            console.error('Error creating customer:', error);
            throw error;
        }
    },

    async updateCustomer(customerId, customerData) {
        try {
            const response = await axios.put(`${API_ENDPOINTS.CUSTOMER}/${customerId}`, customerData);
            return response.data;
        } catch (error) {
            console.error(`Error updating customer with ID ${customerId}:`, error);
            throw error;
        }
    },

    async deleteCustomer(customerId) {
        try {
            await axios.delete(`${API_ENDPOINTS.CUSTOMER}/${customerId}`);
        } catch (error) {
            console.error(`Error deleting customer with ID ${customerId}:`, error);
            throw error;
        }
    }
};

export default CustomerService;
